import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    NavigationSubmenuButton,
    NavigationSubmenuCategory,
    NavigationSubmenuContent,
    NavigationSubmenuWrapper,
    ScrollWrapper
} from "./NavigationItemSubmenu.css";
import {groupBy} from 'lodash';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {Link} from "react-router-dom";
import NavigationContext from "../../../context/navigation.context";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import isDescendant from "utils/isDescendant";
import {animated, useTransition} from "react-spring";
import theme from "utils/theme";

function NavigationItemSubmenu({items, Header, Footer, isVisible}) {

    const context = useContext(NavigationContext.store);
    const {setOpenedSubmenuItem} = context.openedSubmenuItem;
    const {isTopSectionHidden} = context.isTopSectionHidden;

    const [offsetLeft, setOffsetLeft] = useState(window.innerWidth - theme.navigation.sizes.submenuWidth);
    const [isLoaded, setIsLoaded] = useState(false);
    const submenuRef = useRef();

    useEffect(() => {
        setOffsetLeft((submenuRef.current.parentNode.offsetLeft));
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        function handleResize() {
            setOffsetLeft((submenuRef.current.parentNode.offsetLeft));
            setOpenedSubmenuItem(undefined);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [setOpenedSubmenuItem]);

    const groupedItems = useMemo(() => groupBy(
        items,
        item => item.category
    ), [items]);

    const handleSubmenuItemClickAway = useCallback((e) => {
        const navigationItems = document.getElementById("navigation-list");
        const _isDescendant = isDescendant(navigationItems, e.target);

        if (!_isDescendant) {
            setOpenedSubmenuItem(undefined);
        }
    }, [setOpenedSubmenuItem]);

    const itemClickHandler = useCallback(() => {
        setOpenedSubmenuItem(undefined)
    }, [setOpenedSubmenuItem]);

    const transitions = useTransition(isVisible, null, {
        from: {
            opacity: 0,
            position: "sticky",
            width: `${theme.navigation.sizes.submenuWidth}px`,
            margin: "0 8px 0 0",
            left: `calc(${offsetLeft}px - 58px)`,
            transform: "translateX(-30px)",
        },

        enter: {opacity: 1, transform: "translateX(0)"},
        leave: {opacity: 0, transform: "translateX(30px)"},
    });

    const renderMobileDevicesMenu = useMemo(() => {
        return transitions.map(({item, key, props}) =>
            (item &&
                <animated.div key={key} style={props}>
                    <ClickAwayListener onClickAway={handleSubmenuItemClickAway}>
                        <ScrollWrapper offsetLeft={offsetLeft} isTopSectionHidden={isTopSectionHidden}>
                            {Header && <Header/>}
                            <NavigationSubmenuContent>
                                <ul>
                                    {Object.entries(groupedItems).map(([key, groupedItems]) => (
                                        <li key={key}>
                                            <NavigationSubmenuCategory>
                                                {key !== "undefined" && <div className="title">{key}</div>}
                                                <ul>
                                                    {groupedItems.map(categoryItem => (
                                                        categoryItem.to ?
                                                            <li key={categoryItem.to} onClick={itemClickHandler}>
                                                                <Link to={categoryItem.to}>
                                                                    <NavigationSubmenuButton>
                                                                        <ArrowRightIcon className="icon"/>
                                                                        {categoryItem.content}
                                                                    </NavigationSubmenuButton>
                                                                </Link>
                                                            </li> :
                                                            <li key={categoryItem.href} onClick={itemClickHandler}>
                                                                <NavigationSubmenuButton href={categoryItem.href} target="_blank">
                                                                    <ArrowRightIcon className="icon"/>
                                                                    {categoryItem.content}
                                                                </NavigationSubmenuButton>
                                                            </li>
                                                    ))}
                                                </ul>
                                            </NavigationSubmenuCategory>
                                        </li>
                                    ))}
                                </ul>
                            </NavigationSubmenuContent>
                            {Footer && <Footer/>}
                        </ScrollWrapper>
                    </ClickAwayListener>
                </animated.div>
            )
        )
    }, [Footer, Header, groupedItems, handleSubmenuItemClickAway, isTopSectionHidden, itemClickHandler, offsetLeft, transitions]);


    return (
        <NavigationSubmenuWrapper ref={submenuRef}>
            {isLoaded && renderMobileDevicesMenu}
        </NavigationSubmenuWrapper>
    );
}

export default React.memo(NavigationItemSubmenu);