import React from 'react';
import {Wrapper} from './CookieMessage.css';
import CookieConsent from "react-cookie-consent";

function CookieMessage() {

    return (
        <Wrapper className="cookie-message">
            <CookieConsent
                buttonText="Rozumiem"
                cookieName="privacyPolicyCookie"
                style={{
                    position: "relative",
                    maxWidth: "652px",
                    background: "#535353",
                    border: "1px solid rgba(0, 0, 0, .2)",
                    boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.10)",
                }}
                buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                expires={150}>
                <div className="message">
                    <div className="title">Ta strona wykorzystuje pliki cookie.</div>
                    <div className="">Używamy informacji zapisanych za pomocą plików cookies w celu
                        zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu. Jeśli nie wyrażasz zgody,
                        ustawienia
                        dotyczące plików cookies możesz zmienić w swojej przeglądarce.
                    </div>
                </div>
            </CookieConsent>
        </Wrapper>
    );
}

export default CookieMessage;