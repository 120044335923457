import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import NavigationContext from '../../context/navigation.context';
import {List, MobileDevicesMenuContent, MobileDevicesMenuWrapper, NavigationButton, ScrollWrapper} from "./MobileDevicesMenu.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {Link} from "react-router-dom";
import MobileDevicesMenuItemSubmenu from "./MobileDevicesMenuItemSubmenu";
import isDescendant from "utils/isDescendant";


function MobileDevicesMenu({items, Header, Footer}) {

    const context = useContext(NavigationContext.store);
    const {setIsMobileDevicesMenuOpen} = context.openedMobileMenu;
    const {openedMobileMenuSubmenuItem, setOpenedMobileMenuSubmenuItem} = context.openedMobileMenuSubmenuItem;
    const {isTopSectionHidden} = context.isTopSectionHidden;
    const {isMainSectionHidden} = context.isMainSectionHidden;


    useEffect(() => {
        function handleOrientationchange() {
            setIsMobileDevicesMenuOpen(false);
        }

        window.addEventListener('orientationchange', handleOrientationchange);
        return () => window.removeEventListener('orientationchange', handleOrientationchange);
    }, [setIsMobileDevicesMenuOpen]);

    const handleMobileDevicesMenuClickAway = useCallback((e) => {

        const menuButton = document.getElementById("menu-button").parentElement;
        const _isDescendant = isDescendant(menuButton, e.target);
        if (!_isDescendant) {
            setIsMobileDevicesMenuOpen(false);
            setOpenedMobileMenuSubmenuItem(undefined);
        }

    }, [setIsMobileDevicesMenuOpen, setOpenedMobileMenuSubmenuItem]);

    const handleSubmenuItemClick = useCallback((subitem) => {
        if (openedMobileMenuSubmenuItem === subitem)
            setOpenedMobileMenuSubmenuItem(undefined);
        else
            setOpenedMobileMenuSubmenuItem(subitem);
    }, [openedMobileMenuSubmenuItem, setOpenedMobileMenuSubmenuItem]);

    const hideMenu = useCallback(() => {
        setIsMobileDevicesMenuOpen(false);
        setOpenedMobileMenuSubmenuItem(undefined);

    }, [setIsMobileDevicesMenuOpen, setOpenedMobileMenuSubmenuItem]);


    const renderItemSubmenu = useCallback((item) => {
        return (
            <MobileDevicesMenuItemSubmenu isVisible={openedMobileMenuSubmenuItem === item.to}
                                          items={item.subItems} hideMenu={() => hideMenu()}/>
        )
    }, [hideMenu, openedMobileMenuSubmenuItem]);


    const renderItems = useMemo(() => {
        const context = (item) => (
            <NavigationButton isopened={(openedMobileMenuSubmenuItem === item.to).toString()}>
                {item.content}
                {item.subItems && (openedMobileMenuSubmenuItem === item.to ? <ArrowDropDownIcon className="icon"/> : <ArrowDropUpIcon className="icon"/>)}
            </NavigationButton>
        );

        return items.map(item => (
            <li key={item.content} onClick={item.onClick}>
                {item.subItems ?
                    <div onClick={() => handleSubmenuItemClick(item.to)}>
                        {context(item)}
                    </div>
                    : item.onClick ?
                        <div onClick={hideMenu}>
                            {context(item)}
                        </div> :
                        <Link to={item.to} onClick={hideMenu}>
                            {context(item)}
                        </Link>
                }
                {item.subItems && renderItemSubmenu(item)}
            </li>
        ))
    }, [handleSubmenuItemClick, hideMenu, items, openedMobileMenuSubmenuItem, renderItemSubmenu]);


    return (
        <div className="display-md-none">
            <MobileDevicesMenuWrapper isTopSectionHidden={isTopSectionHidden} isMainSectionHidden={isMainSectionHidden}>
                <ClickAwayListener onClickAway={handleMobileDevicesMenuClickAway}>
                    <ScrollWrapper isTopSectionHidden={isTopSectionHidden} isMainSectionHidden={isMainSectionHidden}>
                        {Header && <Header/>}
                        <MobileDevicesMenuContent>
                            <List>
                                {renderItems}
                            </List>
                        </MobileDevicesMenuContent>
                        {Footer && <Footer/>}
                    </ScrollWrapper>
                </ClickAwayListener>
            </MobileDevicesMenuWrapper>
        </div>
    );
}

export default React.memo(MobileDevicesMenu);