import React, {useContext, useEffect, useMemo, useState} from 'react';
import {MainSection, NavigationWrapper, Padding} from "./Navigation.css";
import {ResponsiveWrapper} from "components";
import NavigationContext from "./context/navigation.context";
import {MobileDevicesMenu, NavigationItems, NavigationLogo} from "./components";
import theme from "utils/theme";
import {animated, useTransition} from 'react-spring'

function Navigation({items}) {
    return (
        <NavigationContext.NavigationProvider>
            <NavigationWithContext items={items}/>
        </NavigationContext.NavigationProvider>
    );
}

const NavigationWithContext = React.memo(({items}) => {
    const context = useContext(NavigationContext.store);
    const {isMobileDevicesMenuOpen} = context.openedMobileMenu;
    const {isTransparent, setIsTransparent} = context.isTransparent;


    const [forceMainSectionShow] = useState(false);

    useEffect(() => {

        let offset = theme.navigation.scrollEvents.showNavigationWhileScrollingOffset;
        handleScroll();

        function handleScroll() {
            if (!isMobileDevicesMenuOpen) {
                const _scrollTop = document.scrollingElement.scrollTop;

                if (_scrollTop > offset) {
                    setIsTransparent(false);
                } else {
                    setIsTransparent(true);
                }
            } else {
                setIsTransparent(false);
            }
        }


        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [forceMainSectionShow, isMobileDevicesMenuOpen, setIsTransparent]);

    const transitions = useTransition(isMobileDevicesMenuOpen, null, {
        from: {opacity: 0, position: "fixed", right: 0, transform: "translateX(-30px)"},
        enter: {opacity: 1, transform: "translateX(0)"},
        leave: {opacity: 0, transform: "translateX(30px)"},
    });

    const renderMobileDevicesMenu = useMemo(() => {
        return transitions.map(({item, key, props}) =>
            item &&
            <animated.div key={key} style={props}>
                <MobileDevicesMenu items={items}/>
            </animated.div>)
    }, [items, transitions]);


    return (
        <nav style={{position: "absolute", zIndex: 2048}}>
            <NavigationWrapper id="navigation-wrapper" isTransparent={isTransparent}>
                <ResponsiveWrapper>
                    <Padding>
                        <MainSection>
                            <NavigationLogo {...{
                                alt: "Biuro rachunkowe - Joanna Partyka"
                            }}/>
                            <NavigationItems items={items}/>
                        </MainSection>
                    </Padding>
                </ResponsiveWrapper>
            </NavigationWrapper>

            {renderMobileDevicesMenu}
        </nav>
    );
});

export default React.memo(Navigation);