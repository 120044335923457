import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const RootWrapper = styled.div`
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, .1);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  span.message {
    display: block;
    font-size: ${({theme}) => theme.fontSize.md};
  }
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40px;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  //margin: 4px;
  border: 4px solid;
  border-radius: 50%;
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }

`;
