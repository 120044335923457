import React, {createContext, useState} from 'react';

const initialValue = {};
const store = createContext(initialValue);
const {Provider} = store;

function NavigationProvider({children}) {

    const [openedSubmenuItem, setOpenedSubmenuItem] = useState();
    const [isMobileDevicesMenuOpen, setIsMobileDevicesMenuOpen] = useState(false);
    const [openedMobileMenuSubmenuItem, setOpenedMobileMenuSubmenuItem] = useState();
    const [isTopSectionHidden, setIsTopSectionHidden] = useState(false);
    const [isMainSectionHidden, setIsMainSectionHidden] = useState(false);
    const [isTransparent, setIsTransparent] = useState(true);

    return (
        <Provider value={
            {
                openedSubmenuItem: {openedSubmenuItem, setOpenedSubmenuItem},
                openedMobileMenu: {isMobileDevicesMenuOpen, setIsMobileDevicesMenuOpen},
                openedMobileMenuSubmenuItem: {openedMobileMenuSubmenuItem, setOpenedMobileMenuSubmenuItem},
                isTopSectionHidden: {isTopSectionHidden, setIsTopSectionHidden},
                isMainSectionHidden: {isMainSectionHidden, setIsMainSectionHidden},
                isTransparent: {isTransparent, setIsTransparent},
            }
        }>
            {children}
        </Provider>
    )
}

const NavigationContext = {
    store,
    NavigationProvider
};

export default NavigationContext;