import React from 'react';
import {Container, Wrapper} from "./ResponsiveWrapper.css";

function ResponsiveWrapper({children}) {

    return (
        <Wrapper>
            <Container>
                {children}
            </Container>
        </Wrapper>
    );
}

export default React.memo(ResponsiveWrapper);