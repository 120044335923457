import React from 'react';
import {Wrapper} from './Modal.css';
import MaterialModal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from "@material-ui/core/Card";

function Modal({children, isModalOpen, handleClose}) {

    return (
        <Wrapper>
            <MaterialModal
                open={isModalOpen}
                onClose={handleClose}
                disableScrollLock
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{zIndex: 10000, display: "flex", justifyContent: "center", alignItems: "center", outline: "none", width: "100vw"}}>
                <Fade in={isModalOpen} addEndListener={null}>
                    <Card className="card" style={{outline: "none"}}>
                        {children}
                    </Card>
                </Fade>
            </MaterialModal>
        </Wrapper>
    );
}

export default React.memo(Modal);