import React from 'react';
import {Wrapper} from './Footer.css';
import ResponsiveWrapper from "components/ResponsiveWrapper";
import logo from 'assets/logo-partyka.png';
import {Link} from "react-router-dom";

function Footer() {

    return (
        <Wrapper>
            <ResponsiveWrapper>
                <div className="content">
                    <div className="center">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="Biuro rachunkowe - Joanna Partyka"/>
                            </Link>
                        </div>
                        <div className="info">
                            <div className="text">
                                Księgi&nbsp;handlowe(rachunkowe), księga przychodów&nbsp;i rozchodów, ewidencje ryczałtowe, obsługa kadrowo-płacowa, usługi
                                dodatkowe
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator"/>
                <div className="author-info">
                    <span>

                        &copy; 2020 <span className="company">Biuro usług finansowo - księgowych Joanna Partyka</span> - Creted by:
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href="https://komfox.pl" target="_blank" className="link"> KOMFOX Komputery</a>
                    </span>

                </div>
            </ResponsiveWrapper>
        </Wrapper>
    );
}

export default React.memo(Footer);