import styled, {css} from "styled-components";
import {Button} from "@material-ui/core";

export const List = styled.ul`
  display: flex;
  padding: ${({theme}) => theme.spacing.xs}px 0;
  user-select: none;
  user-focus: none;
  
  li {
    font-size: 0.85rem;
  }

`;

export const NavigationButton = styled(Button)`
    font-size: inherit !important;
    font-weight: 400 !important;
    padding: 10px ${({theme}) => theme.spacing.md}px !important;
    background: none !important;
    //transition: color .4s ease !important;
    height: ${({theme}) => theme.navigation.sizes.mainSectionHeight - 30}px;
    margin: 15px 6px !important;
    color: inherit !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.09);
    //transition: color .4s ease;
    text-transform: capitalize !important;
    //transition: transform .2s ease !important;
    
    &:hover {
      ${({isTransparent}) => !isTransparent ? css`color: ${({theme}) => theme.colors.secondary.normal} !important;` : css`color: inherit !important;`};
      ${({isTransparent}) => !isTransparent ? css`background: none !important;` : css`background: rgba(255,255,255,.1) !important;`};
      //transform: scale(1.1);
      //background: none !important;
    }
    .icon {
      margin-top: -2px;
      margin-right: -13px;
      height: 20px;
      font-size: 2rem;
    }
    
      @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    &:hover {
      ${({isTransparent}) => !isTransparent ? css`color: ${({theme}) => theme.colors.secondary.normal} !important;` : css`color: ${({theme}) => theme.colors.secondary.normal} !important;`};
    }
  }
`;

export const NavigationMobileDevicesMenuButton = styled(Button)`
    font-size: inherit !important;
    font-weight: 400 !important;
    background: none !important;
    transition: color .4s ease !important;
    height: ${({theme}) => theme.navigation.sizes.mainSectionHeight - 30}px;
    ${({isTransparent}) => isTransparent ? css`color: white !important;` : css`color: black !important;`};
    //border: 1px solid black !important;
    
    &:hover {
      //color: ${({theme}) => theme.colors.secondary.normal};
      background: none !important;
    }
`;
