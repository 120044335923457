import styled from "styled-components";

export const Wrapper = styled.div`
  
  background: none;
  position: fixed;
  right: 0;
  bottom: 0;
  opacity: .95;
  transition: opacity .2s ease;
  z-index: 8096;
  
  :hover {
  opacity: 1;
  }
  
  button {
    background: ${({theme}) => theme.colors.secondary.normal} !important;
    color: white !important;
    
  }
  
  .message {
    font-size: .85rem;
    line-height: 1rem;
    padding: 5px;
    .title {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) { 
  bottom: 20px;
  right: 20px;
  padding-left: 20px;
   }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;