import styled from "styled-components";
import {Button} from "@material-ui/core";

export const Wrapper = styled.div`

  min-height: 500px;

  position: relative;

  .triangle {
    position: absolute;
    //width: 100%;
    bottom: 0;
    height: 0;
    width: 0;

    border-top: calc(100vw / 18) solid transparent;
    border-left: 100vw solid ${({theme}) => theme.colors.secondary.normal};
    border-bottom: calc(-100vw / 18) solid transparent;
  }

  .title-wrapper {
    //background: red;
    width: inherit;
    height: 100%;
    //min-height: 400px;

    margin: 100px 0 100px 0;

    .title {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      //padding: 15px;
    }

    .separator {

      width: 100%;
      margin: 15px 0;
      height: 2px;
      display: flex;
      justify-content: center;

      .line {
        height: 2px;
        width: 100%;
        max-width: 120px;
        background: ${({theme}) => theme.colors.secondary.normal};
      }
    }

    .text-wrapper {
      display: flex;
      justify-content: center;

      .text {
        line-height: 1.5rem;
        font-size: 1.0rem;
        text-align: center;
        color: rgba(0, 0, 0, .5);
        max-width: 800px;
      }
    }
  }


  .after-responsive {
    margin: 90px 20px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    .after-responsive {
      margin: 90px 20px;
    }

  .title-wrapper {
    .title {
      font-size: 1.4rem;
    }

    .text-wrapper {
      padding: 0 20px;

      .text {
        line-height: 1.5rem;
        font-size: 1.1rem;
      }
    }
  }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    .triangle {

    border-top: calc(100vw / 25) solid transparent;
    border-left: 100vw solid ${({theme}) => theme.colors.secondary.normal};
    border-bottom: calc(-100vw / 25) solid transparent;
  }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;


export const Grid = styled.div`

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows:  repeat(2, 1fr);
  //grid-column-gap: 85px;
  grid-row-gap: 30px;
  margin-top: 60px;
  margin-bottom: 50px;

  .right {
    font-size: 1rem;
    //padding: 50px 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    color: rgba(0, 0, 0, .6);
    line-height: 1.7rem;
    //background-color: indianred;
    


    .wrapper {
      width: 100%;
          padding: 0 20px;
      > div {
        margin-bottom: 20px;

        .title {
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 1rem;
        }

        .content {
          color: rgba(0, 0, 0, .5);
        }
      }
      
      a {
      transition: border-bottom-color .4s ease;
      border-bottom: 1px solid transparent;
        :hover {
          border-bottom-color: ${({theme}) => theme.colors.secondary.normal};
        }
      }
    }

  }


  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
      .right {
          .wrapper {
            padding: 0;
          }
      }
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 35px;
      
        margin-bottom: 150px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
      grid-column-gap: 70px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
   grid-column-gap: 85px;
  }
`;

export const Map = styled.map`

position:relative;
  min-height: 400px;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, .15);
  overflow: hidden;
  
  .google-frame {
    width: inherit;
    height: inherit;
    border: 0;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;

export const ShowContactModalButton = styled(Button)`
  
  margin-top: 15px !important;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, .15) !important;
  color:rgba(0, 0, 0, .8) !important;
  width: 100% !important;

  :active {
  border-color: transparent !important;
  }
  
  :hover {
   color: ${({theme}) => theme.colors.secondary.normal} !important;
  }
  
  .text {
    padding: 5px 10px;
      font-weight: normal;
      font-size: .8rem;
  }
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;