import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Slider, SliderButtons, SliderDots, SliderTimer} from "./SpringSlider.css";
import {Transition} from 'react-spring/renderprops';
import {animated} from 'react-spring';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

function modulo(n, m) {
    return ((n % m) + m) % m;
}

function SpringSlider({children, time, color, hideDots, hideArrows, hideSlider}) {

    const initialSlide = () => (
        <div style={{background: "orange", display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%"}}>
            <div style={{textAlign: "center"}}>
                <h1>Welcome in my simple slider</h1>
                Please add some slides for proper working
            </div>
        </div>
    );

    if (!children) children = initialSlide();
    const disabled = (!children || !children.length);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const currentSlide = disabled ? children : children[currentSlideIndex];
    const sliderRef = useRef();
    const timerRef = useRef();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resetProgressBar = () => {
        const oldProgressBar = timerRef.current.querySelector(".progress-bar");

        oldProgressBar.style.animationPlayState = "paused";
        oldProgressBar.removeEventListener('animationend', endOfTime);

        let newProgressBar = oldProgressBar.cloneNode(true);
        oldProgressBar.parentNode.replaceChild(newProgressBar, oldProgressBar);
        newProgressBar.addEventListener('animationend', endOfTime);
        newProgressBar.style.animationPlayState = "paused";
    };


    const changeSlideTo = useCallback((index) => {
        resetProgressBar();
        const progressBar = timerRef.current.querySelector(".progress-bar");

        setCurrentSlideIndex(index);
        progressBar.style.animationPlayState = "running";

    }, [resetProgressBar]);

    const changeSlideForward = useCallback(() => {
        let slidesAmount = children.length;
        let nextSlide = modulo(currentSlideIndex + 1, slidesAmount);

        changeSlideTo(nextSlide);
    }, [changeSlideTo, children.length, currentSlideIndex]);

    const changeSlideBackward = useCallback(() => {
        let slidesAmount = children.length;

        let nextSlide = modulo(currentSlideIndex - 1, slidesAmount);
        changeSlideTo(nextSlide);

    }, [changeSlideTo, children.length, currentSlideIndex]);

    useEffect(() => {
        const slider = sliderRef.current;
        const touch = {
            isTouched: false,
            startPos: 0,
        };

        const touchStart = (event) => {
            touch.isTouched = true;
            touch.startPos = event.changedTouches[0].clientX;
        };

        const touchTest = (event) => {
            if (touch.isTouched) {
                let currentPos = event.changedTouches[0].clientX;
                if (currentPos < touch.startPos - 120) {
                    touch.isTouched = false;
                    changeSlideForward();
                    // console.log("change slide forward");
                } else if (currentPos > touch.startPos + 120) {
                    touch.isTouched = false;
                    changeSlideBackward();
                    // console.log("change slide backward");
                }
            }
        };

        slider.addEventListener("touchmove", touchTest);
        slider.addEventListener("touchstart", touchStart);

        return () => {
            slider.removeEventListener("touchmove", touchTest);
            slider.removeEventListener("touchstart", touchStart);
        }
    }, [changeSlideBackward, changeSlideForward]);


    const endOfTime = useCallback(() => {
        changeSlideForward()
    }, [changeSlideForward]);

    useEffect(() => {
        const progressBar = timerRef.current.querySelector(".progress-bar");

        if (disabled) {
            console.warn("There is too low amount of slides. Please add more for proper work")
        } else {
            progressBar.addEventListener('animationend', endOfTime);
            progressBar.style.animationPlayState = "running";
        }

        return () => {
            progressBar.removeEventListener('animationend', endOfTime);
        }
    }, [disabled, endOfTime]);

    // from={{opacity: 0, transform: 'scale(1)'}}
    // enter={{opacity: 1, transform: 'scale(1)'}}
    // leave={{opacity: 0, transform: 'scale(1.2)'}}>
    const renderAnimatedSlides = useCallback(() => {
        return (
            <Transition
                // native
                // reset
                unique
                config={{duration: 300}}
                items={currentSlide} keys={item => item.key}
                from={{opacity: 0,}}
                enter={{opacity: 1,}}
                leave={{opacity: 0,}}>
                {item => props =>
                    <animated.div style={{
                        position: "absolute",
                        width: '100%',
                        height: '100%',

                        backgroundImage: item.props.backgroundImage ? item.props.backgroundImage : 'none',
                        backgroundPosition: "center",
                        backgroundSize: "cover",

                        // top: 0,
                        ...props
                    }}>
                        {/*{React.createElement("div", {style: {width: "100%", height: "100%"}}, item)}*/}
                        {item}
                    </animated.div>}
            </Transition>
        )
    }, [currentSlide]);


    const renderDots = useMemo(() => {
        let amountOfDots = children.length;
        let dots = [];

        for (let i = 0; i < amountOfDots; i++)
            dots.push(<div key={i} className={`dot${i === currentSlideIndex ? ' active' : ''}`} onClick={() => changeSlideTo(i)}/>);

        return (
            <SliderDots color={color} isDisabled={disabled || hideDots}>
                {dots}
            </SliderDots>
        )
    }, [children.length, color, disabled, hideDots, currentSlideIndex, changeSlideTo]);

    return (
        <Slider ref={sliderRef} backgroundImage={currentSlide.props.backgroundImage}>


            {/*  RENDER ALL SLIDERS */}
            {renderAnimatedSlides()}

            {/*  RENDER BUTTONS */}
            <SliderButtons color={color} isDisabled={disabled || hideArrows}>
                <div className="left button" onClick={() => changeSlideBackward()}>
                    <ChevronLeftIcon className="icon"/>
                </div>
                <div className="right button" onClick={() => changeSlideForward()}>
                    <ChevronRightIcon className="icon"/>
                </div>
            </SliderButtons>

            {/*  RENDER DOTS  */}
            {renderDots}

            {/*  RENDER TIMER  */}
            <SliderTimer time={time} color={color} isDisabled={disabled || hideSlider} ref={timerRef}>
                <div className="progress-bar"/>
            </SliderTimer>
        </Slider>
    );
}

export default React.memo(SpringSlider);