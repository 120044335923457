import {createGlobalStyle} from "styled-components";
import {normalize} from "styled-normalize";

export default createGlobalStyle`  
  ${normalize}
  .disable-scroll {
    height: 100% !important;
    overflow-y: hidden !important;
  }
  
  
  body {
    margin: 0;
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    overflow-x: hidden;
    overflow-y: overlay;
  
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba(46, 55, 78, .3)
      }
    }
  
    &::-webkit-scrollbar {
      width: 8px;
      z-index: 5000
    }
  
    &::-webkit-scrollbar-thumb {
      background: none;
      border-radius: 2px;
      box-shadow: inset 0 0 2px rgba(255, 255, 255, .9);
      z-index: 5000;
      transition: background .4s ease;
  
      &:hover {
        background: #1162A7;
      }
 
    }
      
  
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    a {
      color: inherit !important;
      text-decoration: none !important;
    }
  
    .display-none {
      display: none;
    }
  
    .display-block {
      display: block;
    }
  
    .display-flex {
      display: flex;
    }
  
    .display-sm-block {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
        display: block;
      }
    }
  
    .display-md-block {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        display: block;
      }
    }
  
    .display-lg-block {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
        display: block;
      }
  
    }
  
    .display-xl-block {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
        display: block;
      }
    }
  
    .display-sm-flex {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
        display: flex;
      }
    }
  
    .display-md-flex {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        display: flex;
      }
    }
  
    .display-lg-flex {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
        display: flex;
      }
  
    }
  
    .display-xl-flex {
      display: none;
      @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
        display: flex;
      }
    }
  
    .display-sm-none {
      display: initial;
      @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
        display: none;
      }
    }
  
    .display-md-none {
      display: initial;
      @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        display: none;
      }
    }
  
    .display-lg-none {
      display: initial;
      @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
        display: none;
      }
  
    }
  
    .display-xl-none {
      display: initial;
      @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
        display: none;
      }
    }
  }
`;