import styled from "styled-components";
import pattern from 'assets/line-pattern-white.png';

export const Wrapper = styled.div`

  //background: deepskyblue;
  background: #F0F0F0;
  overflow-x: hidden;


  ::before {
    content: '';
    display: block;
    width: 100%;
    height: 7px;
    background-color: #f0f0f0;
    background-image: url(${pattern});
    background-repeat: repeat-x;
  }

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 7px;
    background-color: #f0f0f0;
    background-image: url(${pattern});
    background-repeat: repeat-x;
  }

  .content {
    //background: red;
    width: inherit;
    height: 100%;
    //min-height: 400px;

    margin: 100px 0 100px 0;

    .title {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      //padding: 15px;
    }

    .separator {

      width: 100%;
      margin: 15px 0;
      height: 2px;
      display: flex;
      justify-content: center;

      .line {
        height: 2px;
        width: 100%;
        max-width: 120px;
        background: ${({theme}) => theme.colors.secondary.normal};
      }
    }

    .text-wrapper {
      display: flex;
      justify-content: center;
      padding: 0 20px;

      .text {
        line-height: 1.5rem;
        font-size: 1.0rem;
        text-align: center;
        color: rgba(0, 0, 0, .5);
        max-width: 800px;
      }
    }
  }


  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    .content {
      .title {
        font-size: 1.4rem;
      }

      .text-wrapper {
        padding: 0 20px;

        .text {
          line-height: 1.5rem;
          font-size: 1.1rem;
        }
      }
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;

export const Grid = styled.div`

  //background: khaki;
  display: grid;
  grid-template-columns: 1fr ;
  grid-column-gap: 15px;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 35px;
  margin: 70px 40px;
  //width: 100%;

  .item {
    //margin: 30px;
    //background: deepskyblue;
    display: grid;
    grid-template-columns: 1fr 8fr;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;

    .icon {
      display: flex;
      justify-content: flex-end;
      margin: 0 10px;

      svg {
        font-size: 2rem;
        color: ${({theme}) => theme.colors.secondary.normal};
      }
    }

    .item-content {
      padding: 10px;
      font-size: .9rem;
      //max-width: 200px;
      //background: deepskyblue;

      .item-title {
        margin-bottom: 10px;
        font-weight: 600;
      }

      .item-text {
        color: rgba(0, 0, 0, .5);
      }
    }
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 35px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 35px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;