import styled from "styled-components";

export const Container = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
    max-width: 540px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    max-width: 720px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
    max-width: 1140px;
  }
`;

export const Wrapper = styled.div`
  //width: 100%;
  display: flex;
  justify-content: center;
`;
