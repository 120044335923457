import styled, {css} from "styled-components";
import {Button} from "@material-ui/core";
import theme from "utils/theme";

export const MobileDevicesMenuWrapper = styled.div`
      position: absolute;
      right: 0;
      height: 0;
      width: 100vw;
      
      top: ${({theme, isTopSectionHidden, isMainSectionHidden}) =>
    (!isTopSectionHidden ? theme.navigation.sizes.topSectionHeight : 0) +
    (!isMainSectionHidden ? theme.navigation.sizes.mainSectionHeight : 0)
}px;
      
      transition: top .4s ease;
      padding: 0;
      margin: 0;
  
      background: none;
      
      @media (min-width: ${theme.navigation.sizes.mobileDevicesMenuWidth}px) {
        width: ${({theme}) => theme.navigation.sizes.mobileDevicesMenuWidth}px;
      }
      @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
        width: ${({theme}) => theme.navigation.sizes.mobileDevicesMenuWidth * 1.1}px;
      }
`;

export const ScrollWrapper = styled.div`
  background-color: white;
  position: absolute;
  overflow-y: overlay;
  overflow-x: hidden;
  height: calc(100vh - ${({theme, isTopSectionHidden, isMainSectionHidden}) =>
    ((!isTopSectionHidden && theme.navigation.sizes.topSectionHeight) +
        (!isMainSectionHidden && theme.navigation.sizes.mainSectionHeight))
}px);
  
  transition: height .4s ease;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .06);
  width: inherit;
  padding: 0;
  
  margin: 0;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(46, 55, 78, .3);
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    z-index: 5000;
  }

  &::-webkit-scrollbar-thumb {
    background: none;
    transition: all .9s ease;
    border-radius: 4px;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, .9);
    z-index: 5000;
    &:hover {
        background: #09843E;
    }
  }
`;


export const MobileDevicesMenuContent = styled.div`
  font-size: inherit;
  padding: 20px 40px;
  background: none;

  > ul {
    text-transform: uppercase;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;


export const List = styled.ul`
  display: flex;
  padding: ${({theme}) => theme.spacing.xs}px 0;
  user-select: none;
  user-focus: none;
  margin: 0;
  
  li {
    font-size: ${({theme}) => theme.fontSize.sm} !important;
  }

`;


export const NavigationButton = styled(Button)`
    font-size: inherit !important;
    font-weight: 400 !important;
    padding: 10px ${({theme}) => theme.spacing.md}px !important;
    background: none !important;
    transition: color .4s ease !important;
    height: ${({theme}) => theme.navigation.sizes.mainSectionHeight - 30}px;
    width: 100%;
    
    ${({isopened}) =>
    isopened === "true" &&
    css`
          color: ${({theme}) => theme.colors.secondary.normal} !important;
        `};
    
    justify-content: start !important;
    
    &:hover {
      color: ${({theme}) => theme.colors.secondary.normal};
      background: none !important;
    }
    .icon {
      margin-top: -2px;
      margin-right: -13px;
      height: 20px;
      font-size: 2rem;
    }
`;