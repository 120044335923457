import React from 'react';
import {Wrapper} from './AboutUs.css';
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";

function AboutUs() {

    return (
        <Wrapper id="o-nas">
            <ResponsiveWrapper>
                <div className="content">
                    <div className="title">O nas</div>
                    <div className="separator">
                        <div className="line"/>
                    </div>
                    <div className="text-wrapper">
                        <div className="text">
                            Biuro Usług Finansowo-Księgowych <b>Joanna Partyka</b> to biuro księgowe w&nbsp;Miękini, które działa od&nbsp;2012&nbsp;roku.
                            <br/> <br/>Jesteśmy wiarygodnym biurem księgowym posiadającym <b>Certyfikat Ministerstwa Finansów o&nbsp;numerze
                            56430/2012</b> uprawniającym do
                            usługowego prowadzenia ksiąg rachunkowych. <br/><br/> Nasza oferta skierowana jest do podmiotów o&nbsp;zróżnicowanych formach
                            prawnych
                            działalności gospodarczej – obsługujemy zarówno firmy jednoosobowe, jak i&nbsp;spółki.<br/>
                            <b>Odpowiadamy za ewentualne popełnione błędy</b> – mamy ważne ubezpieczenie OC działalności biura.
                            <br/><br/>Pracujemy od poniedziałku do piątku w&nbsp;godz. 8-16.
                        </div>
                    </div>
                </div>
            </ResponsiveWrapper>
        </Wrapper>
    );
}

export default React.memo(AboutUs);