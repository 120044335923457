import React, {useMemo, useRef, useState} from 'react';
import {Grid, Map, ShowContactModalButton, Wrapper} from './Contact.css';
import {Modal, ResponsiveWrapper} from "components";
import ContactForm from "./ContactForm";


function Contact() {

    const mapRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const renderMap = useMemo(() => (
        <Map className="google-map" ref={mapRef}>
            <iframe
                className="google-frame" title="BIURO USŁUG FINANSOWO - KSIĘGOWYCH Joanna Partyka | mapa"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2102.2939120852116!2d16.74190836013135!3d51.19949304920774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f96f8cc6ceedb%3A0xf1d6e7f52ff5083b!2sDolnobrzeska%2015a%2C%2055-330%20Mi%C4%99kinia!5e0!3m2!1spl!2spl!4v1596982714577!5m2!1spl!2spl"
                frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="-1"/>
        </Map>
    ), []);

    const renderModal = useMemo(() => {
        if (isModalOpen)
            document.body.style.overflowY = "hidden";
        else
            document.body.style.overflowY = "";


        return (
            <Modal isModalOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
                <ContactForm handleClose={() => setIsModalOpen(false)}/>
            </Modal>
        )
    }, [isModalOpen]);

    return (
        <Wrapper id="kontakt">
            <ResponsiveWrapper>
                <div className="after-responsive">
                    <div className="title-wrapper">
                        <div className="title">Kontakt</div>
                        <div className="separator">
                            <div className="line"/>
                        </div>
                        <div className="text-wrapper">
                            <div className="text">
                                Masz pytania lub zainteresowała Cię nasza oferta? Zachęcamy do skontaktowania się z nami! Pracujemy od poniedziałku do piątku w
                                godz. 8-16.
                            </div>
                        </div>
                    </div>

                    <Grid>
                        {renderMap}
                        <div className="right">
                            <div className="wrapper">
                                <div className="address">
                                    <div className="title">Adres</div>
                                    <div className="content">
                                        ul.&nbsp;Dolnobrzeska&nbsp;15A<br/>
                                        55-330 Miękinia
                                    </div>
                                </div>
                                <div className="contact-info">
                                    <div className="title">Dane kontaktowe</div>
                                    <div className="content">
                                        <a href="mailto:joanna@biuropartyka.pl">E-mail: joanna@biuropartyka.pl</a><br/>
                                        <a href="tel:+48 668 198 962">Telefon: +48 668 198 962</a>
                                    </div>
                                </div>

                                <ShowContactModalButton onClick={() => setIsModalOpen(true)}>
                                    <div className="text">
                                        Wyświetl formularz kontaktowy
                                    </div>
                                </ShowContactModalButton>
                            </div>
                        </div>
                    </Grid>
                </div>
            </ResponsiveWrapper>
            <div className="triangle"/>

            {renderModal}
        </Wrapper>
    );
}

export default React.memo(Contact);