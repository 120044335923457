import React, {useMemo} from 'react';
import {ThemeProvider} from "styled-components";
import {CookieMessage, Footer, LoadingIndicator, Navigation} from "components";
import theme from "utils/theme";
import GlobalStyles from './styles/global.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {HomePage, PageNotFound} from "pages";

function App() {
    const navigationItems = useMemo(() => [
        {content: "O nas", to: "/#o-nas"},
        {content: "Usługi", to: "/#uslugi"},
        {content: "Kontakt", to: "/#kontakt"},
    ], []);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>


            <Router>
                <Navigation items={navigationItems}/>

                {/*<ScrollToTop/>*/}
                <Switch>
                    <Route path="/" exact component={HomePage}/>
                    <Route path="/*" component={PageNotFound}/>
                </Switch>

                <Footer/>

            </Router>

            <CookieMessage/>
        </ThemeProvider>
    );
}

function RootApp() {
    return (
        <React.Suspense fallback={<LoadingIndicator/>}>
            <App/>
        </React.Suspense>
    );
}

export default RootApp;
