import React, {useCallback, useContext, useMemo} from 'react';
import {List, NavigationButton, NavigationMobileDevicesMenuButton} from "./NavigationItems.css";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {Link} from "react-router-dom";
import NavigationContext from "../../context/navigation.context";
import NavigationItemSubmenu from "./NavigationItemSubmenu";
import MenuIcon from '@material-ui/icons/Menu';

import {SubmenuHeader} from "./NavigationItemSubmenu/NavigationItemSubmenu.css";

function NavigationItems({items}) {

    const context = useContext(NavigationContext.store);
    const {setOpenedSubmenuItem, openedSubmenuItem} = context.openedSubmenuItem;
    const {isMobileDevicesMenuOpen, setIsMobileDevicesMenuOpen} = context.openedMobileMenu;
    const {setOpenedMobileMenuSubmenuItem} = context.openedMobileMenuSubmenuItem;

    const {isTransparent} = context.isTransparent;

    const handleSubmenuItemClick = useCallback((subitem) => {
        if (openedSubmenuItem === subitem)
            setOpenedSubmenuItem(undefined);
        else
            setOpenedSubmenuItem(subitem);
    }, [openedSubmenuItem, setOpenedSubmenuItem]);


    const renderItemSubmenu = useCallback((item) => {

        const isSubmenuVisible = openedSubmenuItem === item.to;

        return (
            <NavigationItemSubmenu
                isVisible={isSubmenuVisible}
                items={item.subItems}
                Header={SubmenuHeader}/>

        )
    }, [openedSubmenuItem]);

    {/*<NavigationButton isTransparent={isTransparent}>*/
    }
    const renderItems = useMemo(() => {

        const context = (item) => (


            <NavigationButton isTransparent={isTransparent}>
                {item.content}
                {item.subItems && <ArrowDropDownIcon className="icon"/>}
            </NavigationButton>

        );

        return items.map(item => (
            <li key={item.content} onClick={item.onClick}>
                {item.subItems ?
                    <div onClick={() => handleSubmenuItemClick(item.to)}>
                        {context(item)}
                    </div>
                    : item.onClick ?
                        <div onClick={() => handleSubmenuItemClick(null)}>
                            {context(item)}
                        </div> :
                        <Link to={item.to} onClick={() => handleSubmenuItemClick(null)}>
                            {context(item)}
                        </Link>
                }
                {item.subItems && renderItemSubmenu(item)}
            </li>
        ))
    }, [handleSubmenuItemClick, items, renderItemSubmenu, isTransparent]);

    const toggleMobileDevicesMenu = useCallback(() => {
        if (isMobileDevicesMenuOpen) {
            setIsMobileDevicesMenuOpen(false);
            setOpenedMobileMenuSubmenuItem(undefined);
        } else {
            setIsMobileDevicesMenuOpen(true);
        }

    }, [isMobileDevicesMenuOpen, setIsMobileDevicesMenuOpen, setOpenedMobileMenuSubmenuItem]);


    // <NavigationMobileDevicesMenuButton id="menu-button" onClick={toggleMobileDevicesMenu} isTransparent={isTransparent}>
    return (
        <nav>
            <div className="display-md-block">
                <List id="navigation-list">
                    {renderItems}
                </List>
            </div>
            <div className="display-md-none">
                <NavigationMobileDevicesMenuButton id="menu-button" onClick={toggleMobileDevicesMenu} isTransparent={isTransparent}>
                    <MenuIcon className="icon"/>
                </NavigationMobileDevicesMenuButton>
            </div>
        </nav>
    );
}

export default React.memo(NavigationItems);