import styled from "styled-components";
import {NavigationButton} from "../NavigationItems.css";


export const NavigationSubmenuWrapper = styled.div`
  position: absolute;
  background-color: white;
  height: 0;
  width: 100%;
  right: 0;
`;

const submenuBottomMargin = 30;
export const ScrollWrapper = styled.div`
  position: relative;
  background-color: inherit;
  //position: sticky;
  overflow-y: overlay;
  overflow-x: hidden;
  height: auto;
  min-height: 100px;
  max-height: calc(100vh - ${({theme, isTopSectionHidden}) => (!isTopSectionHidden ? theme.navigation.sizes.topSectionHeight : 0) + theme.navigation.sizes.mainSectionHeight + submenuBottomMargin}px);
  
  transition: max-height .4s ease;
  
  width: 100%;
  
  // width: 550px;
  // left: calc(${({offsetLeft}) => offsetLeft}px - 58px);
  box-shadow: 0 5px 5px rgba(0, 0, 0, .06);
    //box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.1);

  padding: 0;
  //margin: 0 8px 0 0;
  margin: 0;

  
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(46, 55, 78, .3);
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    z-index: 5000;
  }

  &::-webkit-scrollbar-thumb {
    background: none;
    transition: all .9s ease;
    border-radius: 4px;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, .9);
    z-index: 5000;
    &:hover {
        background: #09843E;
    }
  }
`;

export const SubmenuHeader = styled.div`
  font-size: inherit;
  padding: 0;
  height: 2px;
  width: 100%;
  
  background-image: linear-gradient(90deg, transparent, ${({theme}) => theme.colors.secondary.normal}, ${({theme}) => theme.colors.secondary.normal}, transparent);
  position: fixed;
  top: 0;
  
  //border-top: 2px solid ${({theme}) => theme.colors.secondary.normal};

`;

export const NavigationSubmenuContent = styled.div`
  font-size: inherit;
  padding: 20px 50px 50px 60px;
  background-color: white;

  > ul {
    text-transform: uppercase;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NavigationSubmenuButton = styled(NavigationButton)`
   width: 100%;
   min-height: 40px;
   height: auto;
   margin: 0 20px !important;
   text-align: left !important;
   display: flex !important;
   justify-content: right!important;
   //align-items: start!important;
   padding: 5px 15px !important;
   text-transform: none!important;
   color: rgba(0, 0, 0, .6) !important;
    &:hover {
      color: ${({theme}) => theme.colors.secondary.normal} !important;
      background: none !important;
    }
    
    .icon {
      margin-top: -2px;
      margin-right: 0;
      margin-left: -13px;
      height: 20px;
      font-size: 2rem;
    }
    
`;


export const NavigationSubmenuCategory = styled.div`
   width: 100%;
   min-height: 40px;
   height: auto;
   color: ${({theme}) => theme.colors.secondary.normal};
   
   .title {
    padding:  15px 15px 0 15px;
   }

    ul {
      padding-top: 15px;
    }
`;