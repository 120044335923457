import React, {useEffect, useState} from 'react';
import {AboutUs, Contact, HomePageHeader, Services} from "./components";
import {useHistory} from "react-router-dom";
import scrollToElem from "utils/smoothScrollTo";
import {LoadingIndicator} from "components";

function HomePage() {

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const history = useHistory();
    useEffect(() => {
        scrollToElem(getElementToScroll());
    });

    useEffect(() => {
        const timeout = setTimeout(function () {
            setIsPageLoaded(true);
        }, 300);
        return () => {
            clearTimeout(timeout)
        }
    }, []);

    const getElementToScroll = () => {
        switch (history.location.hash) {
            case "#kontakt" : {
                return document.getElementById("kontakt");

            }
            case "#o-nas" : {
                return document.getElementById("o-nas");

            }
            case "#uslugi" : {
                return document.getElementById("uslugi");

            }
            default : {
                return document.getElementsByTagName("main")[0];
            }
        }
    };


    return (
        <React.Fragment>
            <main>

                <HomePageHeader/>
                <AboutUs/>
                <Services/>
                <Contact/>

            </main>
            <LoadingIndicator show={!isPageLoaded}/>

        </React.Fragment>

    );
}

export default React.memo(HomePage);