import styled, {css, keyframes} from "styled-components";


export const Slide = styled.div`
    background: none;
    width: 100%;
    height: 100%;
    color: inherit;
`;

const progressAnimation = keyframes`
    0% {
      width: 0
    }
    100% {
      width: 100%
    }
`;

export const Slider = styled.section`
    position: relative;
    display: block;
    background: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    ${({backgroundImage}) => css`
       background-image: ${backgroundImage};
       background-position: center;
       background-size: cover;
    `};

`;


export const SliderButtons = styled.div`
    font-size: 3rem;
    color: ${({color}) => color};
    ${({isDisabled}) => isDisabled ? css`visibility: hidden` : css`visibility: visible`};
    
    .button {
        font-size: inherit;
        position: absolute;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        top: 50%;
        width: 50px;
        height: 50px;
        background: none;
        cursor: pointer;
        transition: transform .2s ease;
    
        :hover {
            transform: scale(1.5);
        }
        
        .icon {
            font-size: inherit;
        }
    }
  
    .left {
        left: 0
    }
  
    .right {
        right: 0
    }
`;

export const SliderDots = styled.div`
        position: absolute;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        bottom: 20px;
        left: calc(50% - 100px);
        width: 200px;
        height: 50px;
        background: none;
        
        ${({isDisabled}) => isDisabled ? css`visibility: hidden` : css`visibility: visible`};
         
        .dot {
            width: 7px;
            height: 7px;
            border: 1.5px solid #969696;
            background: #969696;
            border-radius: 13px;
            margin: 0 10px;
            cursor: pointer;
            transition: background .2s ease, transform .2s ease, border-color .2s ease;
            
            :hover {
                background: ${({color}) => color};
                border-color: ${({color}) => color};
                transform: scale(1.2);
            }
            
                    
            &.active {
                background: ${({color}) => color};
                border-color: ${({color}) => color};
                transform: scale(1.4);
            }
        }

`;


export const SliderTimer = styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: rgba(255,255,255,.3);
        ${({isDisabled}) => isDisabled ? css`visibility: hidden` : css`visibility: visible`};
            
        .progress-bar {
            border-radius: 0;
            height: 100%;
            background: ${({color}) => color};
            animation: ${progressAnimation};
            animation-duration: ${({time}) => time}ms;
            animation-iteration-count: 1;
            animation-fill-mode: backwards;
            animation-play-state: paused;
            animation-timing-function: linear;
        }
`;