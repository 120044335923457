import React, {useContext} from 'react';
import {Link as StyledLink, Logo} from "./NavigationLogo.css"
import {Link} from "react-router-dom";

import logoNormal from 'assets/logo-partyka-white.png';
import logoTransparent from 'assets/logo-partyka.png';
import NavigationContext from "../../context/navigation.context";

function NavigationLogo({alt}) {
    const context = useContext(NavigationContext.store);
    const {isTransparent} = context.isTransparent;

    return (
        <StyledLink>
            <Link to="/">
                <Logo src={`${!isTransparent ? logoTransparent : logoNormal}`} alt={alt}/>
            </Link>
        </StyledLink>
    );
}

export default NavigationLogo;