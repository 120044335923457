import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Content, SubmenuButton, SubmenuCategory, SubmenuContent, Wrapper} from "./MobileDevicesMenuItemSubmenu.css";
import {Link} from "react-router-dom";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {groupBy} from "lodash";

function MobileDevicesMenuItemSubmenu({isVisible, items, Header, Footer, hideMenu}) {

    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef();

    useEffect(() => {
        setContentHeight(contentRef.current.clientHeight)
    }, [contentRef, setContentHeight]);


    const groupedItems = useMemo(() => groupBy(
        items,
        item => item.category
    ), [items]);


    return (
        <Wrapper isVisible={isVisible} contentHeight={contentHeight}>
            <Content ref={contentRef}>

                {Header && <Header/>}
                <SubmenuContent>
                    <ul>
                        {Object.entries(groupedItems).map(([key, groupedItems]) => (
                            <li key={key}>
                                <SubmenuCategory>
                                    {key !== "undefined" && <div className="title">{key}</div>}
                                    <ul>
                                        {groupedItems.map(categoryItem => (
                                            categoryItem.to ?
                                                <li key={categoryItem.to} onClick={hideMenu}>
                                                    <Link to={categoryItem.to}>
                                                        <SubmenuButton>
                                                            <ArrowRightIcon className="icon"/>
                                                            {categoryItem.content}
                                                        </SubmenuButton>
                                                    </Link>
                                                </li> :
                                                <li key={categoryItem.href} onClick={hideMenu}>
                                                    <SubmenuButton href={categoryItem.href} target="_blank">
                                                        <ArrowRightIcon className="icon"/>
                                                        {categoryItem.content}
                                                    </SubmenuButton>
                                                </li>
                                        ))}
                                    </ul>
                                </SubmenuCategory>
                            </li>
                        ))}
                    </ul>
                </SubmenuContent>
                {Footer && <Footer/>}

            </Content>
        </Wrapper>
    );
}

export default React.memo(MobileDevicesMenuItemSubmenu);