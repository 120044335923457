import styled from "styled-components";
import {Button} from "@material-ui/core";

export const Wrapper = styled.footer`

  background-color: ${({theme}) => theme.colors.secondary.normal};
  color: white;
  //min-height: 300px;
  font-size: 1rem;
  position:relative;
  
  .triangle {
    position: absolute;
    //width: 100%;
    bottom: 0;
    height: 0;
    width: 0;

    border-top: calc(100vw / 18) solid transparent;
    border-left: 100vw solid white;
    border-bottom: calc(-100vw / 18) solid transparent;
  }


  .content {
    //background-color: indianred;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;

    .center {
      width: 100%;
      text-align: center;

    }

    .logo {
      //margin-top: 20px;
      position: absolute;
      background: white;
      width: 100%;
      left: 0;
      top: 10px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 200px;
      }
 
    }

    .info {
      margin: 100px 40px 25px 40px;
      line-height: 1.4rem;
      font-size: .7rem;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      .text {
        max-width: 700px;
      }

    }

  }

  .separator {
    height: 1px;
    margin: 0 30px;
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), transparent);
    opacity: .2;

  }

  .author-info {
    //background-color: grey;
    font-size: .65rem;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1rem;
    margin: 0 40px;
    color: rgba(255, 255, 255, .4);

    .company {
      color: rgba(255, 255, 255, .7);
      text-transform: capitalize;
    }

    .link {
      cursor: pointer;

      :hover {
        color: rgba(255, 255, 255, .7) !important;
      }
    }
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    .content {
      .logo {
        img {
          width: 220px;
        }

      }

      .info {
        font-size: .8rem;
      }
    }

    .author-info {
      //background-color: grey;
      font-size: .75rem;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 1rem;
      margin: 0 20px;
      color: rgba(255, 255, 255, .4);
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {

  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;

export const FacebookButton = styled(Button)`

  justify-content: center !important;
  align-items: center !important;
  padding: 8px 20px !important;
  color: ${({theme}) => theme.colors.secondary.normal} !important;
  font-weight: normal !important;


  .icon {
    color: rgba(255, 255, 255, .3);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: -3px;
      margin-right: 6px;
      font-size: 1.4rem;

    }
  }

  .text {
    color: rgba(255, 255, 255, .3);
    font-size: .7rem !important;

  }

  :hover {
    .icon {
      color: rgba(255, 255, 255, 1);
    }

    .text {
      color: rgba(255, 255, 255, 1);
    }
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;