import styled from "styled-components";

export const Wrapper = styled.main`
  
  min-height: 100vh;
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, .4);
  
  .center{
    text-align: center;
    margin: 50px;
  }
  
  h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
    color: black;
  }
  
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1rem;
    color: rgba(0, 0, 0, .34);
    font-weight: normal;
  }
 
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) { 

  h1 {
    font-size: 5rem;
  }
    h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  
   }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;