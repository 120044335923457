import React, {useRef} from 'react';
import {ExitButton, SubmitButton, Wrapper} from './ContactForm.css';
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import LoopIcon from '@material-ui/icons/Loop';

function ContactForm({handleClose}) {

    const modal = useRef(null);

    const recaptcha = useRef(null);

    const handleFormSubmit = (data) => {

        // Show loading overlay
        modal.current.classList.add("loading");

        axios.post(`/api/sendEmail`, {data})
            .then(res => {
                // console.log("then ok");
                if (res.status === 200) {
                    // console.log("then ok 200");
                    modal.current.querySelector(".loading-wrapper").classList.add("success");
                } else {
                    // console.log("then ok else");
                    modal.current.querySelector(".loading-wrapper").classList.add("uncaught-error");
                }
            })
            .catch(e => {
                // console.log("then nok");
                // show error message
                let messageComponent = modal.current.querySelector(".messages>.error");
                messageComponent.classList.add("visible");

                if (e.response.status === 551) {
                    console.info(e.response.data);
                    messageComponent.innerHTML = `Wystąpił problem podczas weryfikacji reCaptcha. </br>Prawdopodobnie pole "Nie jestem robotem" nie zostało zaznaczone lub wygasło. Proszę zaznaczyć pole ponownie i spróbować jeszcze raz.`;
                } else {
                    messageComponent.innerHTML = `Wystąpił problem podczas wysyłania wiadomości. </br>Wygląda na to, że problem leży po naszej stronie. Proszę spróbować póżniej lub napisać do nas wiadomość bezpośrednio na adres: <a href="mailto:joanna@biuropartyka.pl">joanna@biuropartyka.pl</a><br>Przepraszamy za utrudnienia.`;
                }

                // Reset recaptcha object
                recaptcha.current.reset();

                // Remove loading overlay
                modal.current.classList.remove("loading");
            });

    };

    const checkAndSubmitForm = (e) => {
        e.preventDefault();

        // Current contact form
        let form = e.target;

        let person = form.querySelector('[name="person"]').value;
        let email = form.querySelector('[name="email"]').value;
        let message = form.querySelector('[name="message"]').value;
        let _recaptcha = recaptcha.current.getValue();


        let formData = {
            person: person,
            email: email,
            message: message,
            recaptcha: _recaptcha,
        };

        // console.log(formData);

        handleFormSubmit(formData);
    };

    return (
        <Wrapper>
            <form ref={modal} className="send-mail-form" onSubmit={(e) => {
                checkAndSubmitForm(e)
            }}>

                <div className="header">
                    <div className="title">Skontaktuj się z nami!</div>
                    <div className="exit-button-wrapper">
                        <ExitButton onClick={handleClose}>
                            <CloseIcon/>
                        </ExitButton>
                    </div>
                </div>
                <div className="separator"/>
                <div className="body">
                    <div className="info">
                        Masz pytania lub zainteresowała Cię nasza oferta? Zachęcamy do skontaktowania się z nami! Pracujemy od poniedziałku do piątku w godz.
                        8-16.
                    </div>
                    <div className="required">
                        * Pola wymagane
                    </div>

                    {/* Form inputs wrapper */}
                    <div className="form-wrapper loading">

                        <div className="form-item">
                            <div className="header-wrapper">
                                Imię i nawisko:
                            </div>
                            <div className="input-wrapper">
                                <input type="text" name="person"
                                       placeholder="Imię i nazwisko lub nazwa firmy"/>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="header-wrapper"><span className="star">*</span>
                                Adres e-mail:
                            </div>
                            <div className="input-wrapper">
                                <input type="email" name="email" required placeholder="twoj-email@domena.pl"/>
                            </div>
                        </div>

                        <div className="form-item">
                            <div className="header-wrapper"><span className="star">*</span>
                                Treść wiadomości:
                            </div>
                            <div className="textarea-wrapper">
                                <textarea required name="message" placeholder=""/>
                            </div>
                        </div>


                        <div className="form-item">
                            <div className="header-wrapper"><span className="star">*</span>
                                Authentykacja:
                            </div>
                            <div className="recaptcha-wrapper">
                                {/* Recaptcha object */}
                                <ReCAPTCHA
                                    ref={recaptcha}
                                    name="recaptcha"
                                    className="recaptcha-obj"
                                    sitekey="6LffrsoZAAAAAKSbbS4ZyEUI8m3VGZC1zeHhPfgM"
                                />
                            </div>
                        </div>

                        <div className="messages">
                            <div className="error">
                            </div>
                        </div>

                    </div>

                    {/* Loading message overlay */}
                    <div className="loading-wrapper">

                        <div className="content text-center">

                            <div className="still-loading">
                                <div className="ico">
                                    <LoopIcon/>
                                </div>
                                <div className="text">
                                    Wysyłanie wiadomości<br/>
                                    Proszę czekać...
                                </div>
                            </div>
                            <div className="success">
                                <div className="text">
                                    Wiadomość została wysłana.<br/><br/>
                                    <div className="font-weight-normal">
                                        Dziękujemy za skontaktowanie się z nami!<br/>
                                        Postaramy się odpowiedzieć tak szybko jak to możliwe. :)
                                    </div>
                                </div>
                            </div>
                            <div className="uncaught-error">
                                <div className="text">
                                    Niestety podczas wysyłania wiadomości wystąpił nieznany błąd.<br/><br/>
                                    <div className="font-weight-normal">
                                        Postaramy się naprawić ten problem jak najszybciej.<br/>
                                        Proszę spróbuj ponownie później.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="separator"/>
                <div className="footer">
                    <div className="info-text">Wysyłając do nas wiadomość, wyrażasz zgodę na przetwarzanie swoich danych osobowych w celach kontaktowych.</div>
                    <div className="submit-button-wrapper">
                        <SubmitButton type="submit">
                            Wyślij
                        </SubmitButton>
                    </div>
                </div>
            </form>
        </Wrapper>
    );
}

export default React.memo(ContactForm);