import React from 'react';
import {ContactInfoWrapper, Content, Email, Icon, Link, Phone} from "components/Navigation/components/ContactInfo/ContactInfo.css";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

function ContactInfo({phone, email}) {

    return (
        <ContactInfoWrapper>
            {email && <Email>
                <Link href={`mailto:${email}`}>
                    <Icon><EmailOutlinedIcon className="icon"/></Icon>
                    <Content>{email}</Content>
                </Link>
            </Email>}

            {phone && <Phone>
                <Link href={`tel:${phone}`}>
                    <Icon><PhoneIphoneIcon className="icon"/></Icon>
                    <Content>{phone}</Content>
                </Link>
            </Phone>}
        </ContactInfoWrapper>
    );
}

export default React.memo(ContactInfo);