import styled from "styled-components";

export const Wrapper = styled.div`

  //background: deepskyblue;

  .content {
    //background: red;
    width: inherit;
    height: 100%;
    
    //min-height: 400px;

    margin: 70px 0 100px 0;

    .title {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      //padding: 15px;
    }

    .separator {

      width: 100%;
      margin: 20px 0;
      height: 2px;
      display: flex;
      justify-content: center;

      .line {
        height: 2px;
        width: 100%;
        max-width: 120px;
        background: ${({theme}) => theme.colors.secondary.normal};
      }
    }

    .text-wrapper {
      display: flex;
      justify-content: center;
      padding: 0 20px;

      .text {
        line-height: 1.5rem;
        font-size: 1.0rem;
        text-align: center;
        color: rgba(0, 0, 0, .5);
        max-width: 800px;
      }
    }
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    .content {
      margin: 70px 0 100px 0;
    

    .title {
      font-size: 1.4rem;
    }

    .text-wrapper {
      padding: 0 20px;

      .text {
        line-height: 1.5rem;
        font-size: 1.07rem;
      }
    }
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;