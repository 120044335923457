import styled, {keyframes} from "styled-components";
import {Button} from "@material-ui/core";

export const rotate = keyframes`
  from {
  transform: rotate(0deg);
  }
  to {
  transform: rotate(-360deg);
  }
`;

export const Wrapper = styled.div`

  position: relative;
  //background: pink;
  min-height: 300px;
  max-width: 650px;
  padding: 20px 15px;
  max-height: 90vh;
  overflow-y: overlay;
  //background: pink;
  width: 95vw;
  box-sizing: border-box;


  .messages {
    margin-top: 20px;

    .error {
      font-weight: normal;
      display: none;
      color: #DF2026;

      &[class*="visible"] {
        display: block;
      }

      a {
        text-decoration: underline !important;
        color: #a2181b !important;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding-left: 15px;

    .title {
      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: 1px;
    }

    .exit-button-wrapper {
      width: 50px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .separator {
    //position:relative;
    display: block;
    content: "";
    height: 1px;
    //width: 100%;
    background-image: linear-gradient(90deg, transparent, ${({theme}) => theme.colors.secondary.normal}, transparent);
    margin: 10px 20px 20px 20px;
  }

  .body {
    font-size: 13px;
    font-weight: bold;
    margin: 0 30px 50px 30px;
    position:relative;


      .recaptcha-wrapper {
        width: 100%;
        height: 80px;
        //overflow: hidden;
        position: relative;
        //background: thistle;
        box-sizing: border-box;
        //transform: scale(0.8);
        .recaptcha-obj iframe {
          transform: scale(0.85);
          margin-left: -22px;
          width: 100%;
          box-sizing: border-box;
        }
      }


    .textarea-wrapper {

      display: inline-block;
      max-width: 100%;

      textarea {
        box-sizing: border-box;
        resize: vertical;
        width: 100%;
        //min-width: 100%;
        //max-width: 100%;
        min-height: 120px;
        font-size: 14px;
        height: 35px;
        padding: 5px 10px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 5px;
        color: rgba(0, 0, 0, .6);

        :focus {
          outline: none;
          border-color: rgba(0, 0, 0, .4);
        }

        ::placeholder {
          color: rgba(0, 0, 0, .3);
        }
      }
    }

    .info {
      font-weight: normal;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, .5);
    }


    .form-wrapper {
      width: 100%;
      //background: pink;
      .form-item {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        margin: 20px 0;
        //width: 500px;

        .header-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-right: 20px;
          margin-bottom: 5px;
          font-weight: 600;
        }

        .input-wrapper {
          max-width: inherit;
          width: 100%;
          display: block;
          position: relative;
          margin: 0;
          padding: 0;

          input {
            box-sizing: border-box;
            width: 100%;
            font-size: 14px;
            height: 35px;
            padding: 5px 10px;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: 5px;
            color: rgba(0, 0, 0, .6);

            :focus {
              outline: none;
              border-color: rgba(0, 0, 0, .4);


            }

            ::placeholder {
              color: rgba(0, 0, 0, .3);
            }

          }

        }
      }

    }

    .required {
      font-size: 14px;
      color: #DF2026;
      font-weight: normal;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .star {
      font-size: 14px;
      color: #DF2026;
      padding-right: 3px;
      margin-top: -2px
    }
  }

  .footer {
    display: flex;
    text-align: left;
    font-weight: normal;
    color: rgba(0, 0, 0, .5);
    font-size: 13px;
    margin: 0 20px;

    .submit-button-wrapper {
      padding-left: 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 250px;
      //background: khaki;
    }
  }


  .loading-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: center;

    visibility: hidden;
    opacity: 0;
    transition-duration: .2s;
    transition-property: opacity, visibility;
    transition-delay: 0s, .2s;

    .ico {
      //background: red;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 4rem;
        animation: ${rotate};
        animation-duration: 4s;
        animation-iteration-count: infinite;
      }
    }

    .success {
      display: none;
    }

    .uncaught-error {
      display: none;
    }

  }

  form[class*="loading"] {
    .loading-wrapper {
      visibility: visible;
      opacity: 1;
      transition-duration: .2s;
      transition-property: visibility, opacity;
      transition-delay: 0s;

      &[class*="success"] .still-loading {
        display: none;
      }

      &[class*="success"] .success {
        display: block;
      }

      &[class*="uncaught-error"] .still-loading {
        display: none;
      }

      &[class*="uncaught-error"] .uncaught-error {
        display: block;
      }
    }

    .submit-button-wrapper button {
      /*display: none;*/
      background-color: rgba(0, 123, 255, .4) !important;
      pointer-events: none;
    }
  }


  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    width: 100vw;
    padding: 50px 50px;
    .header {
      display: flex;
      align-items: center;

      .title {
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 1px;
      }

      .exit-button-wrapper {
        //width: 50px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .separator {
      //position:relative;
      display: block;
      content: "";
      height: 1px;
      //width: 100%;
      background-image: linear-gradient(90deg, transparent, ${({theme}) => theme.colors.secondary.normal}, transparent);
      margin: 30px 20px;
    }

    .body {
      font-size: 14px;
      font-weight: bold;
      margin: 0 30px 50px 30px;

      .recaptcha-wrapper {
        width: 100%;
        height: 80px;
        position: relative;
        box-sizing: border-box;
        .recaptcha-obj iframe {
          transform: scale(1);
          margin-left: 0;
          width: 100%;
          box-sizing: border-box;
        }
      }

      .textarea-wrapper {

        display: inline-block;
        max-width: 100%;

        textarea {
          box-sizing: border-box;
          resize: vertical;
          width: 100%;
          //min-width: 100%;
          //max-width: 100%;
          min-height: 120px;
          font-size: 14px;
          height: 35px;
          padding: 5px 10px;
          border: 1px solid rgba(0, 0, 0, .2);
          border-radius: 5px;
          color: rgba(0, 0, 0, .6);

          :focus {
            outline: none;
            border-color: rgba(0, 0, 0, .4);
          }

          ::placeholder {
            color: rgba(0, 0, 0, .3);
          }
        }
      }

      .info {
        font-weight: normal;
        margin-bottom: 15px;
        color: rgba(0, 0, 0, .5);
      }


      .form-wrapper {
        width: 100%;
        //background: pink;
        .form-item {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: 1fr 2fr;
          margin: 8px 0;
          //width: 500px;

          .header-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            font-weight: 600;
          }

          .input-wrapper {
            max-width: inherit;
            width: 100%;
            display: block;
            position: relative;
            margin: 0;
            padding: 0;

            input {
              box-sizing: border-box;
              width: 100%;
              font-size: 14px;
              height: 35px;
              padding: 5px 10px;
              border: 1px solid rgba(0, 0, 0, .2);
              border-radius: 5px;
              color: rgba(0, 0, 0, .6);

              :focus {
                outline: none;
                border-color: rgba(0, 0, 0, .4);


              }

              ::placeholder {
                color: rgba(0, 0, 0, .3);
              }

            }

          }
        }

      }

      .required {
        font-size: 14px;
        color: #DF2026;
        font-weight: normal;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .star {
        font-size: 14px;
        color: #DF2026;
        padding-right: 3px;
        margin-top: -2px
      }
    }

  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;


export const SubmitButton = styled(Button)`
  
  color: white !important;
  background: ${({theme}) => theme.colors.secondary.normal} !important;
  padding: 10px 25px !important;
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;
export const ExitButton = styled(Button)`
  
  //color: white !important;
  //background: ${({theme}) => theme.colors.secondary.normal} !important;
  padding: 10px 25px !important;
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;