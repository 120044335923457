import React, {useEffect, useState} from 'react';
import {Wrapper} from './PageNotImplementedYet.css';
import ResponsiveWrapper from "components/ResponsiveWrapper";
import {LoadingIndicator} from "components";

function PageNotImplementedYet() {

    window.scrollTo(0, 0);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(function () {
            setIsPageLoaded(true);
        }, 300);
        return () => {
            clearTimeout(timeout)
        }
    }, []);

    return (
        <React.Fragment>
            <Wrapper>

                {/*<SubpageHeader/>*/}
                <ResponsiveWrapper>
                    <div className="flex">
                        <div className="center">
                            <h1>404</h1>
                            <h2>Ta strona nie została&nbsp;jeszcze zaimplementowana... :(</h2>
                            <h3>Nasza strona jest ciągle w budowie. Jeżeli masz jakiekolwiek pytania zachęcamy do kontaktu z nami!</h3>
                        </div>
                    </div>
                </ResponsiveWrapper>
            </Wrapper>

            <LoadingIndicator show={!isPageLoaded}/>
        </React.Fragment>
    );
}

export default React.memo(PageNotImplementedYet);