import styled from "styled-components";
import {NavigationButton} from "components/Navigation/components/NavigationItems/NavigationItems.css";


export const Wrapper = styled.div`
      max-height: ${({isVisible, contentHeight}) => isVisible ? contentHeight : 0}px;

      transition: all 0.8s ease;
      overflow: hidden;
`;

export const Content = styled.div`
    background: pink;
`;


export const SubmenuContent = styled.div`
  font-size: inherit;
  padding: 0 20px;
  background-color: white;

  > ul {
    text-transform: uppercase;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;


export const SubmenuCategory = styled.div`
   width: 100%;
   min-height: 40px;
   height: auto;
   color: ${({theme}) => theme.colors.secondary.normal};
   
   .title {
    padding:  15px 15px 0 15px;
   }

    ul {
      padding-top: 15px;
    }
`;


export const SubmenuButton = styled(NavigationButton)`
   width: 100%;
   min-height: 40px;
   height: auto;
   margin: 0 20px !important;
   text-align: left !important;
   display: flex !important;
   justify-content: right!important;
   //align-items: start!important;
   padding: 5px 15px !important;
   text-transform: none!important;
   color: rgba(0, 0, 0, .6) !important;
    &:hover {
      color: ${({theme}) => theme.colors.secondary.normal} !important;
      background: none !important;
    }
    
    .icon {
      margin-top: -2px;
      margin-right: 0;
      margin-left: -13px;
      height: 20px;
      font-size: 2rem;
    }
    
`;

