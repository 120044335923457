import React from 'react';
import {CheckOfferButton, Grid, Wrapper} from './HomePageHeader.css';

import logo from 'assets/logo-partyka.png';
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper";
import {Link} from "react-router-dom";

function HomePageHeader() {

    return (
        <Wrapper>
            <div className="gradient-wrapper">
                <div className="color-wrapper">
                    <ResponsiveWrapper>
                        <Grid>
                            <div className="text-wrapper">
                                <div className="text">

                                    <h1>Biuro rachunkowe<br/>Joanna Partyka</h1>
                                    <h2>Profesjonalne usługi finansowo-księgowe</h2>
                                    <div className="separator"/>
                                    <Link to="/#uslugi">
                                        <CheckOfferButton>Sprawdź ofertę</CheckOfferButton>
                                    </Link>
                                </div>
                            </div>
                            <div className="offer-wrapper">
                                <div className="logo-background">
                                    <div className="logo-wrapper">
                                        <img src={logo} alt="Biuro rachunkowe Joanna Partyka"/>

                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </ResponsiveWrapper>
                </div>

            </div>
            <div className="triangle"/>
        </Wrapper>
    );
}

export default React.memo(HomePageHeader);