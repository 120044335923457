import styled, {css} from "styled-components";

export const NavigationWrapper = styled.div`
    background-color: ${({isTransparent}) => isTransparent ? 'none' : 'white'};
    ${({isTransparent}) => isTransparent ? css`background-color: none;` : css`background-color: white`};
     ${({isTransparent}) => isTransparent ? css`color: white;` : css`color: black`};
    
    ${({isTransparent}) => !isTransparent && css` box-shadow: 2px 2px 10px rgba(0, 0, 0, .08);`};
    
    
    
    position: fixed;
    width: 100vw;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.09);
    padding: 0;
  
    transition: transform .4s ease, background-color .4s ease, color .4s ease;
    transform: translateY(-${({theme, isTopSectionHidden, isMainSectionHidden}) => (
    isTopSectionHidden ? isMainSectionHidden ? theme.navigation.sizes.topSectionHeight + theme.navigation.sizes.mainSectionHeight : theme.navigation.sizes.topSectionHeight : 0
)}px);


  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
       color: black !important;
  }
`;


export const MainSection = styled.div`
    width: 100%;
    height: ${({theme}) => theme.navigation.sizes.mainSectionHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
`;

export const Padding = styled.div`
    padding: 0 40px;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
      padding: 0 20px;
    }
`;