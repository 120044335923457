import styled from "styled-components";

export const ContactInfoWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    font-size: ${({theme}) => theme.fontSize.sm};
    font-weight: 400;
    opacity: .9;
    color: inherit;
    
    >div + div {
      padding-left: 28px;
    }
    
    .icon {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, .8);
    }
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: border-color .3s ease;
    &:hover {
        border-color: ${({theme}) => theme.colors.secondary.normal};;
    }
`;

const FlexAlignItems = styled.div`
    display: flex;
    height: 100%;
    align-items: flex-end;
`;

export const Email = styled(FlexAlignItems)`
    .icon {
      margin-top: 3px;
    }
`;

export const Phone = styled(FlexAlignItems)``;

export const Icon = styled.div`
    color: inherit;
  
    .icon {
      color: inherit;
    }
`;

export const Content = styled.div`
    padding-left: 8px;
`;