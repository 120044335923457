import React from 'react';
import {Content, ContentWrapper, Root, RootWrapper} from "./LoadingIndicator.css";
import {animated, useTransition} from 'react-spring'

function LoadingIndicator({show}) {

    const transitions = useTransition(show, null, {
        from: {position: 'fixed', width: "100vw", height: "100vh", top: 0, zIndex: 10000, opacity: 1},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {
            duration: 200
        }
    });

    return transitions.map(({item, key, props}) =>
        item && <animated.div key={key} style={props}>
            <RootWrapper>
                <ContentWrapper>
                    <Root>
                        <Content/>
                        <Content/>
                        <Content/>
                        <Content/>
                    </Root>
                    <span className="message">Trwa ładowanie</span>
                </ContentWrapper>
            </RootWrapper>
            ✌️</animated.div>);
}

export default React.memo(LoadingIndicator);