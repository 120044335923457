export default {
    companyName: "Biuro Partyka",
    spacing: {
        xs: 5,
        sm: 10,
        md: 15,
        xl: 20
    },
    colors: {
        primary: {
            normal: "#51ACD8"
        },
        secondary: {
            normal: "#1162A7"
        },
    },
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },
    fontSize: {
        sm: ".72rem",
        md: "1rem",
        lg: "1.4rem",
        xl: "1.8rem",
        h1: "2rem"
    },
    navigation: {
        sizes: {
            topSectionHeight: -1,
            mainSectionHeight: 90,
            logoWidth: 110,
            submenuWidth: 550,
            mobileDevicesMenuWidth: 300,
        },
        scrollEvents: {
            hideTopSectionAt: 30,
            hideMainSectionAt: 150,
            showNavigationWhileScrollingOffset: 10,
            setTransparentNavigationAt: 30
        }
    },
    slider: {
        slideTime: 15000,
    },
    pages: {
        pageParallaxHeaderHeight: 250
    }
}