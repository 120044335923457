import React, {useEffect} from 'react';
import {Wrapper} from './404.css';
import ResponsiveWrapper from "components/ResponsiveWrapper";
import {useHistory} from "react-router-dom";

function PageNotFound() {
    const history = useHistory();

    window.scrollTo(0, 0);

    useEffect(() => {
        const timeout = setTimeout(function () {
            history.push('/');
        }, 5000);
        return () => {
            clearTimeout(timeout)
        }
    }, [history]);

    return (
        <Wrapper>
            <ResponsiveWrapper>
                <div className="center">
                    <h1>404</h1>
                    <h2>Ta strona nie istnieje... :(</h2>
                    <h3>Za kilka sekund zostaniesz przeniesiony na stronę główną.</h3>
                </div>
            </ResponsiveWrapper>
        </Wrapper>
    );
}

export default React.memo(PageNotFound);