import styled, {css} from "styled-components";
import {Button} from "@material-ui/core";

export const Wrapper = styled.div`

  //background-image: url(${({backgroundImage}) => backgroundImage});
  background: ${({theme}) => theme.colors.secondary.normal};
  background-size: cover;
  background-position: 20% 15%;
  //background-attachment: fixed;
  //padding-top: 100px;
  height: 70vh;
  min-height: 500px;
  width: 100%;
  position: relative;

  .gradient-wrapper {
    position: absolute;
    //display: none;
    top: 0;
    left: 0;
    //background-image: radial-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .55));
    //background: rgb(17,98,167);

    width: 100%;
    height: 100%;

    // .color-wrapper {
    //   transition: background-color .3s ease;
    //
    //   ${({isScrolled}) => isScrolled ? css`background-color: rgba(17,98,167, .6)` : css`background-color: none`};
    //   width: 100%;
    //   height: 100%;
    // }
  }

  .triangle {
    position: absolute;
    //width: 100%;
    bottom: 0;
    height: 0;
    width: 0;

    border-top: calc(100vw / 18) solid transparent;
    border-left: 100vw solid white;
    border-bottom: calc(-100vw / 18) solid transparent;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }

  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    //background-position: 10% 15%;
    //height: 90vh;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    background-position: 50% 15%;
    height: 80vh;
    .triangle {
      border-top: calc(100vw / 25) solid transparent;
      border-left: 100vw solid white;
      border-bottom: calc(-100vw / 25) solid transparent;
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
  }
`;


export const Grid = styled.div`

  display: grid;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  //margin: 0 100px 0 100px; 
  color: white;
  //height: 85vh;
  height: calc(70vh - 100px);
  grid-template-columns: 1fr;
  grid-column-gap: 30px;

  > div {
    margin-top: 90px;
    //border: 2px solid pink;
    //height: 100%;
  }

  .text-wrapper {
    display: flex;
    width: 100%;
    //height: 100%;
    justify-content: center;
    align-items: center;

    .text {
      padding: 0 40px;

      h1 {
        font-size: 2.2rem;
        line-height: 2.3rem;
        font-weight: 800;
        letter-spacing: .1rem;
      }

      h2 {
        font-weight: normal;
        font-size: 1.4rem;
      }

      .separator {
        height: 2px;
        background: white;
        margin: 30px 0;
      }
    }
  }

  .offer-wrapper {
    position: relative;
    display: none;

    .logo-background {
      background: white;
      position: absolute;
      overflow: hidden;
      //bottom: -100px;
      //width: 500px;
      top: -120px;
      height: calc(80vh + 40px);
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .logo-wrapper {
        width: 80%;

        img {
          width: 100%;
        }
      }

    }
  }


  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    //height: calc(90vh - 100px);
    //grid-template-columns: 3fr 1fr;
    //grid-column-gap: 30px;
    //.text-wrapper {
    //  .text {
    //    padding: 0 40px;
    //
    //    h1 {
    //      font-size: 3rem;
    //      line-height: 3rem;
    //      font-weight: 600;
    //      letter-spacing: .1rem;
    //    }
    //
    //    h2 {
    //      font-weight: normal;
    //    }
    //
    //  }
    //}
  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    height: calc(80vh - 100px);
    grid-template-columns: 3fr 3fr;
    grid-column-gap: 30px;
    .text-wrapper {
       justify-content: flex-start;
      .text {
        padding: 0 40px;

        h1 {
          font-size: 2.5rem;
          line-height: 3rem;
          //font-weight: 600;
          letter-spacing: .1rem;
        }

        h2 {
          font-weight: normal;
        }

      }
    }
    
      .offer-wrapper {
    display: block;
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
      .text-wrapper {
       justify-content: flex-start;
      .text {
        padding: 0 40px;

        h1 {
          font-size: 3rem;
          line-height: 4rem;
          //font-weight: 600;
          letter-spacing: .1rem;
        }

        h2 {
          font-weight: normal;
        }

      }
    }
  }
`;

export const CheckOfferButton = styled(Button)`

    background: white !important;
    padding: 6px 27px !important;
    text-transform: initial !important;
  
  
  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.md}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {  }
`;