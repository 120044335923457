import React from 'react';
import {Grid, Wrapper} from './Services.css';
import ResponsiveWrapper from "../../../../components/ResponsiveWrapper/RespornsiveWrapper";
import CheckIcon from '@material-ui/icons/Check';

function Services() {

    return (
        <Wrapper id="uslugi">
            <ResponsiveWrapper>
                <div className="content">
                    <div className="title">Usługi</div>
                    <div className="separator">
                        <div className="line"/>
                    </div>
                    <div className="text-wrapper">
                        <div className="text">
                            Nasze biuro rachunkowe oferuje szereg usług w zakresie finansów i księgowości. Sprawdź sam lub skontaktuj się z nami, aby otrzymać
                            indywidualną ofertę.
                        </div>
                    </div>
                    <Grid>
                        <div className="item">
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="item-content">
                                <div className="item-title">księgi handlowe(rachunkowe)</div>
                                <div className="item-text">Potrzebujesz pomocy przy prowadzeniu ksiąg handlowych? Chętnie pomożemy i odpowiemy na wszystkie pytania!</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="item-content">
                                <div className="item-title">księga przychodów i rozchodów</div>
                                <div className="item-text">Pomagamy w prowadzeniu księgi przychodów i rozchodów. Chcesz dowiedzieć się więcej? Skontaktuj się z nami!</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="item-content">
                                <div className="item-title">ewidencje ryczałtowe</div>
                                <div className="item-text">Nie wiesz jak powinna wyglądać i być prowadzona ewidencja przychodów na ryczałcie? Służymy pomocą!</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="item-content">
                                <div className="item-title">obsługa kadrowo-płacowa</div>
                                <div className="item-text">Oferujemy usługę prowadzenia prac dotyczących szeroko pojętych kadr oraz płac. Możesz zaufać naszemu doświadczeniu!</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <CheckIcon/>
                            </div>
                            <div className="item-content">
                                <div className="item-title">usługi dodatkowe</div>
                                <div className="item-text">Potrzebujesz dodatkowej pomocy? Skontaktuj się z nami!</div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </ResponsiveWrapper>
        </Wrapper>
    );
}

export default React.memo(Services);