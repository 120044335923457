import styled from "styled-components";

export const Logo = styled.img`
    width: ${({theme}) => theme.navigation.sizes.logoWidth}px;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        width: ${({theme}) => theme.navigation.sizes.logoWidth * 1.1}px
    }
    @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
        width: ${({theme}) => theme.navigation.sizes.logoWidth * 1.3}px
    }
`;

export const Link = styled.div`
    user-select: none;
    cursor: pointer;
`;
